<template>
  <div class="layout">
    <HeaderControllerSkeleton hide-reward-popup />
    <main class="layout__main">
      <div v-if="isCriticalLoaded" class="layout__main-background">
        <NuxtImg
          class="layout__main-background__left"
          loading="lazy"
          decoding="async"
          :src="bgImages.left"
          provider="localProvider"
        />
        <NuxtImg
          class="layout__main-background__right"
          loading="lazy"
          decoding="async"
          :src="bgImages.right"
          provider="localProvider"
        />
      </div>
      <div class="layout__main-page">
        <slot />
      </div>
    </main>
    <FooterControllerSkeleton />
  </div>
</template>

<script setup lang="ts">
import { usePromoStore } from '~/features/payment/store/promo.store';
import { useResultsStore } from '~/features/payment/store/results.store';
import { EResultSuccessTypes, EResultTypes } from '~/features/payment/types/result/client.types';
import { useCountriesStore } from '~/features/payment/store/countries.store';
import { useBonusesStore } from '~/features/payment/store/bonuses.store';

import axeBgGray from '~/project-layers/dota-global/assets/images/payment/axe.png';
import enigmaBgGray from '~/project-layers/dota-global/assets/images/payment/enigma.png';
import enigmaBloggerBgGray from '~/project-layers/dota-global/assets/images/payment/enigmaBlogger.png';
import axeBloggerBgGray from '~/project-layers/dota-global/assets/images/payment/axeBlogger.png';
import axeBg from '~/assets/images/Payment/axe-bg.avif';
import axeBloggerBg from '~/assets/images/Payment/axe-blogger-bg.avif';
import enigmaBg from '~/assets/images/Payment/enigma-bg.avif';
import enigmaBloggerBg from '~/assets/images/Payment/enigma-blogger-bg.avif';

// TODO: импортировать и game, чтобы картинки под кс подставить, когда таковые появятся.
const { theme } = useProjectSettings();
const route = useRoute();
const baseName = useRouteBaseName()(route);

const promoStore = usePromoStore();
const { bloggerPromo } = storeToRefs(promoStore);

const resultsStore = useResultsStore();
const { result } = storeToRefs(resultsStore);

const bonusesStore = useBonusesStore();
const { isLoaded: bonusesLoaded } = storeToRefs(bonusesStore);

const countriesStore = useCountriesStore();
const { isLoaded: countriesLoaded } = storeToRefs(countriesStore);

const isCriticalLoaded = computed(() => {
  if (baseName === ROUTING.PAYMENT.MAIN.name) {
    return countriesLoaded.value && bonusesLoaded.value;
  }

  return true;
});

const bgImages = computed(() => {
  const isResultBlogger =
    result.value?.status === EResultTypes.SUCCESS && result.value.type === EResultSuccessTypes.BLOGGER;
  const isBlogger = bloggerPromo.value || isResultBlogger;

  if (theme == 'gray') {
    return {
      left: isBlogger ? axeBloggerBgGray : axeBgGray,
      right: isBlogger ? enigmaBloggerBgGray : enigmaBgGray,
    };
  }

  return {
    left: isBlogger ? axeBloggerBg : axeBg,
    right: isBlogger ? enigmaBloggerBg : enigmaBg,
  };
});
</script>

<style lang="scss" scoped src="assets/layouts/payment.scss"></style>
