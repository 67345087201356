import type { ITopLiveDropApiData } from '~/utils/liveDrop/parseWSData.types';
import { getDropType, getModificatorOfItem, getRarity, isMulticastOrMultifix } from '~/utils/liveDrop/parseWSData';
import type { TTopLiveDropData } from '~/project-layers/dota/features/live-drop/api/types/client.types';

export const LIVE_ITEMS_MAX_LENGTH = 50;

const mappedLiveDropItem = (
  { itemData, additionalData, userData, caseData, inventoryId }: ITopLiveDropApiData,
  isUltraRare: boolean,
) => ({
  id: itemData.id,
  images: {
    default: itemData.image,
    hover: itemData.imageHover,
  },
  isUltraRare,
  modificator: getModificatorOfItem(additionalData?.percent, getDropType(itemData.gameName, itemData.caseType)),
  rarity: getRarity(itemData.quality),
  quality: itemData.quality,
  user: {
    ...userData,
    nickname: userData.name,
  },
  case: {
    ...caseData,
    name: caseData?.caseName,
    price: caseData?.price,
    type: caseData?.caseType,
  },
  additional: {
    ...additionalData,
    percent: additionalData?.percent,
  },
  isMulticastOrMultifix: isMulticastOrMultifix(itemData.caseType),
  gameName: itemData.gameName,
  type: itemData.type,
  name: itemData.name,
  price: itemData.price,
  inventoryId,
});

export const transform = (apiData: ITopLiveDropApiData[], isUltraRare = false): TTopLiveDropData => {
  return apiData.slice(0, LIVE_ITEMS_MAX_LENGTH).reduce((acc, item) => {
    if (acc[item.itemData.gameName]) {
      acc[item.itemData.gameName].push(mappedLiveDropItem(item, isUltraRare));
    } else {
      acc[item.itemData.gameName] = [mappedLiveDropItem(item, isUltraRare)];
    }
    return acc;
  }, {} as TTopLiveDropData);
};
