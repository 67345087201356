<template>
  <div :class="['case-battle-carousel', mainClasses]">
    <div class="case-battle-carousel__rounds">
      <SharedNumberTicker
        :max-number="cases.length"
        :current-number="isActiveRound || isDoneRound ? Number(roundsCounter) : null"
        :spin-endless="isActiveRound"
      />
    </div>
    <div v-if="!props.isDoneRound" class="case-battle-carousel__cases">
      <TransitionGroup name="battles-mini-case" @before-leave="onBeforeLeave" @after-leave="isLeave = false">
        <BattlesMiniCase
          v-for="(item, index) in showCases"
          :key="item.uniqId"
          :class="caseClasses"
          :image="item.image"
          :active="index === 0"
          :multiplier="1"
          disable-hover-effects
        >
          <SharedText class="case-battle-carousel__cases-item__price" v-bind="casesPriceOptions">
            {{ GlobalUtils.Currency.formatCurrency(item.price) }}
          </SharedText>
          <SharedMiniLabel
            v-if="item.multifix"
            class="case-battle-carousel__cases-item__multi"
            :bg-color="BattlesGradientConfigs.MULTIFIX"
          >
            FIX {{ item.multifix }}x
          </SharedMiniLabel>
          <SharedMiniLabel
            v-if="item.multicastRange && BattlesUtils.Validity.checkWhetherDataRangeValid(item.multicastRange)"
            class="case-battle-carousel__cases-item__multi"
            :bg-color="BattlesGradientConfigs.MULTICAST"
          >
            MC x{{ item.multicastRange[0] }}-x{{ item.multicastRange[1] }}
          </SharedMiniLabel>
        </BattlesMiniCase>
      </TransitionGroup>
    </div>
    <Transition name="buttons-group">
      <div v-if="changeMD(true, false) && isDoneRound" class="case-battle-carousel__cases__info__group">
        <SharedButtonsGroup :border-radius="8" :direction="EButtonsGroupDirection.ROW" :gap="2">
          <SharedKitButton
            :color-preset="BattleColorPresets.TRANSPARENT_WHITE"
            :size-preset="actionButtonsSize"
            :type-preset="TypePresets.NONE"
            :text="$t('battles.battlesList')"
            :link-props="{
              to: ROUTING.BATTLES.MAIN.path,
            }"
          >
            <template #prepend>
              <SvgoArrowLeftIcon :width="backArrowSize" :height="backArrowSize" />
            </template>
          </SharedKitButton>
          <SharedKitButton
            :color-preset="BattleColorPresets.MAIN"
            :size-preset="actionButtonsSize"
            :type-preset="TypePresets.NONE"
            :text="props.isAuth ? $t('battles.createTheSame') : $t('login')"
            @click="handleBattleCreate"
          >
          </SharedKitButton>
        </SharedButtonsGroup>
      </div>
    </Transition>
    <div
      class="case-battle-carousel__cases__info"
      :class="!isDoneRound && 'case-battle-carousel__cases__info_non-width'"
    >
      <div v-if="changeMD(false, true) && isDoneRound" class="case-battle-carousel__cases__info__group_small">
        <SvgoBackArrow @click="() => navigateTo(localeRoute(ROUTING.BATTLES.MAIN))" />
        <SvgoRefresh @click.once="handleBattleCreate" />
      </div>
      <div class="case-battle-carousel__cases__info__counts">
        <BattlesCaseBattleCarouselPlayersShareWidget
          :link="props.link"
          :viewers="props.viewers"
          :sum="props.sum"
          :rounds="props.rounds"
          :players="props.players"
          :is-final="props.isDoneRound"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { BattleColorPresets } from '../../presets/SharedKitButton.presets';
import { useBattlesDataStateStore } from '../../store/dataState.store';
import type { ICaseBattleCarouselProps, IEmitsCaseBattleCarousel } from './CaseBattleCarousel.types';
import { Colors } from '~/constants/colors.constants';
import { FontSizesNumber, FontWeights } from '~/types/SharedFont.types';
import { BattlesGradientConfigs } from '~/features/battles/constants/styles';
import BattlesUtils from '~/features/battles/utils';
import { SizePresets, TypePresets } from '~/components/SharedKitButton/SharedKitButton.presets';
import { BattlesSpinnerTimeouts } from '~/features/battles/constants/rules';
import { EButtonsGroupDirection } from '~/components/SharedButtonsGroup/SharedButtonsGroup.types';
import { useAuthPopupStore } from '~/features/general-auth/store/popup.store';

const localeRoute = useLocaleRoute();

const emits = defineEmits<IEmitsCaseBattleCarousel>();
const props = withDefaults(defineProps<ICaseBattleCarouselProps>(), {
  players: () => {
    return { all: 0 };
  },
  rounds: () => {
    return { all: 0 };
  },
  sum: 0,
  viewers: 0,
});

const authPopupStore = useAuthPopupStore();

const changeMD = GlobalUtils.Media.changeByMedia('md');

const backArrowSize = computed(() => changeXL(24, 20));

const battlesDataStateStore = useBattlesDataStateStore();

const mainClasses = computed(() => ({
  'case-battle-carousel-active': props.isActiveRound,
}));

const actionButtonsSize = computed(() => changeXL(SizePresets.M, SizePresets.S));

const initialMiniBattles = props.cases.map((el, i) => ({ ...el, uniqId: i }));

const showCases = computed(() => {
  const startIndex = !props.rounds.current ? 0 : props.rounds.current - 1;
  return initialMiniBattles.slice(startIndex < 0 ? 0 : startIndex);
});

const roundsCounter = computed(() => {
  return String(props.isActiveRound ? props.rounds.current || 0 : props.rounds.all);
});

const changeXL = GlobalUtils.Media.changeByMedia('xl');

const casesPriceOptions = computed(() => ({
  color: Colors.MAIN.GOLD,
  size: changeXL(FontSizesNumber.MEDIUM, FontSizesNumber.SMALL_2XS),
  weight: FontWeights.BOLD,
}));

const handleBattleCreate = () => {
  if (props.isAuth) {
    emits('onCreateTheSame');
  } else {
    authPopupStore.open();
    battlesDataStateStore.dataState.isAuthNeeded = true;
  }
};

// когда идет анимация исчезновения элемента
const isLeave = ref(false);
const isAnimate = ref(false);

const onBeforeLeave = () => {
  isLeave.value = true;
  isAnimate.value = true;
};

const caseClasses = computed(() => ['case-battle-carousel__cases-item', { 'cases-item-leave': isLeave.value }]);

let animateTimeout: ReturnType<typeof setTimeout>;

watch(isAnimate, (val) => {
  if (!val) return;
  animateTimeout = setTimeout(() => (isAnimate.value = false), BattlesSpinnerTimeouts.changeCaseAnimation);
});

onUnmounted(() => {
  clearTimeout(animateTimeout);
});
</script>

<style src="./CaseBattleCarousel.scss" scoped lang="scss"></style>
<style scoped lang="scss">
.battles-mini-case {
  --remove-case: v-bind(`${BattlesSpinnerTimeouts.changeCaseAnimation}ms`);
}
</style>
