<template>
  <div :class="['base-tooltip', classes]" @mouseenter="handleEnter" @mouseleave="handleLeave" @click="handleClick">
    <div ref="preview" class="base-tooltip__preview">
      <slot name="preview-icon">
        <SvgoQuestionIcon class="base-tooltip__preview-icon" />
      </slot>
    </div>
    <Teleport v-if="appendToBody" to="body">
      <div ref="tooltip" class="base-tooltip__content" :style="tooltipStyles">
        <slot />
      </div>
    </Teleport>
    <div v-else ref="tooltip" class="base-tooltip__content">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, onBeforeUnmount, ref, computed, watch } from 'vue';
import type { IBaseTooltipProps } from './BaseTooltip.types';
import { OBSERVATION_PROCENT, POSITIONS } from './BaseTooltip.data';

const changeXL = GlobalUtils.Media.changeByMedia('xl');

const props = defineProps<IBaseTooltipProps>();
const { position, isOpened, showOnMobile } = toRefs(props);

const tooltip = ref<HTMLDivElement | null>(null);
const preview = ref<HTMLDivElement | null>(null);

const prefferedPosition = ref(position.value);
const temporaryPositionIndex = ref(POSITIONS.indexOf(prefferedPosition.value));

const showContent = ref(isOpened.value);

const tooltipStyles = ref<Record<string, string>>({});
const updateTooltipPosition = () => {
  if (!tooltip.value || !preview.value) return;

  const previewRect = preview.value.getBoundingClientRect();
  const tooltipRect = tooltip.value.getBoundingClientRect();

  let top = previewRect.bottom + window.scrollY;
  let left = previewRect.left + window.scrollX;

  if (prefferedPosition.value === 'top') {
    top = previewRect.top - tooltipRect.height + window.scrollY;
  } else if (prefferedPosition.value === 'left') {
    left = previewRect.left - tooltipRect.width + window.scrollX;
  } else if (prefferedPosition.value === 'right') {
    left = previewRect.right + window.scrollX;
  }

  tooltipStyles.value = {
    position: 'absolute',
    top: `${top}px`,
    left: `${left}px`,
    zIndex: 'var(--ui-layer-9)',
    opacity: showContent.value ? '1' : '0',
  };
};

const classes = computed(() => ({
  [`base-tooltip--${prefferedPosition.value}`]: true,
  'base-tooltip--active': showContent.value,
  'base-tooltip--mobile': showOnMobile.value,
}));

watch([showContent, prefferedPosition], () => {
  if (showContent.value) {
    updateTooltipPosition();
  }
});

onMounted(() => {
  if (!tooltip.value || !preview.value) return;
  updateTooltipPosition();
});

onBeforeUnmount(() => {
  if (!observer) return;
  observer.disconnect();
});

let observer: IntersectionObserver;

const handleEnter = () => {
  if (!tooltip.value) return;

  observer = new IntersectionObserver(([tooltipEntry]) => {
    const percentage = tooltipEntry.intersectionRatio;
    observer.disconnect();

    const isVisible = percentage > OBSERVATION_PROCENT;
    if (!isVisible) {
      temporaryPositionIndex.value = (temporaryPositionIndex.value + 1) % POSITIONS.length;
      prefferedPosition.value = POSITIONS[temporaryPositionIndex.value];
    }

    const isPrefferedPositionSame = prefferedPosition.value === position.value;

    if (isVisible || isPrefferedPositionSame) {
      showContent.value = true;
      return;
    }

    handleEnter();
  });

  observer.observe(tooltip.value);
};

const handleLeave = () => {
  showContent.value = false;

  if (!observer) return;
  updateTooltipPosition();
  observer.disconnect();
};

const handleClick = () => {
  if (!changeXL(false, true) || !showOnMobile.value) return;
  showContent.value = !showContent.value;
};
</script>

<style scoped lang="scss" src="./BaseTooltip.scss" />
