<template>
  <div class="number-ticker">
    <div :class="backgroundClasses" @animationiteration="handleBackgroundAnimationIteration" />
    <span :class="contentClasses" @animationiteration="handleContentAnimationIteration">
      {{ numberTickerText }}
    </span>
  </div>
</template>

<script setup lang="ts">
import type { ISharedBattleRoundProps } from './SharedNumberTicker.types';

const props = withDefaults(defineProps<ISharedBattleRoundProps>(), { spinEndless: false });

const isBackgroundAnimating = toRef(props.spinEndless);
const isContentAnimating = ref(false);

function handleBackgroundAnimationIteration() {
  if (props.spinEndless) return;
  isBackgroundAnimating.value = false;
}

function handleContentAnimationIteration() {
  isContentAnimating.value = false;
}

const numberTickerText = computed(() => {
  if (props.currentNumber) {
    return `${Math.abs(props.currentNumber)}/${Math.abs(props.maxNumber)}`;
  } else {
    return `${Math.abs(props.maxNumber)}`;
  }
});

watch(
  () => props.maxNumber,
  (newVal, oldVal) => {
    if (newVal === 0 || newVal <= oldVal) return;
    isBackgroundAnimating.value = true;
    isContentAnimating.value = true;
  },
);

const backgroundClasses = computed(() => ({
  'number-ticker__background': true,
  'number-ticker__background--animating': isBackgroundAnimating.value,
}));

const contentClasses = computed(() => ({
  'number-ticker__content': true,
  'number-ticker__content--total': props.currentNumber,
  'number-ticker__content--animating': isContentAnimating.value,
}));
</script>

<style src="./SharedNumberTicker.scss" scoped lang="scss" />
