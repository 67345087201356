<template>
  <section class="action-buttons">
    <div v-if="isHost && !isWaiting" class="action-buttons__content">
      <BattlesActionButtonsGroup class="action-buttons__content_group">
        <template #top>
          <SharedKitButton
            :text="$t('battles.actionButtons.startNow')"
            :is-loading="props.buttonsState.startingWithPlayers"
            :is-disabled="props.buttonsState.startingWithBots"
            v-bind="presetBtn('primary', { typePreset: defineRadiusForButtons('top') })"
            @click.once="$emit('start')"
          >
            <template v-if="isDesktop" #append>
              <BaseTooltip position="top">
                {{ textTooltipStartedNowButton }}
              </BaseTooltip>
            </template>
          </SharedKitButton>
        </template>
        <template #bottom>
          <SharedKitButton
            :text="$t('battles.startWithBots')"
            :is-loading="props.buttonsState.startingWithBots"
            :is-disabled="props.buttonsState.startingWithPlayers"
            v-bind="presetBtn('bot', { typePreset: defineRadiusForButtons('bottom') })"
            :class="{ 'action-buttons__with-bots': isWaitingTimeForHost }"
            @click.once="$emit('start-bots')"
          >
            <template v-if="isDesktop" #prepend>
              <SvgoPlayerBotIcon :height="24" :width="24" />
            </template>
            <template v-if="isDesktop" #append>
              <BaseTooltip position="bottom">
                {{ $t('battles.battleStartInfo') }}
              </BaseTooltip>
            </template>
          </SharedKitButton>
        </template>
      </BattlesActionButtonsGroup>
    </div>
    <div v-if="isGuestWithoutOffer" class="action-buttons__content">
      <BattlesActionButtonsGroup class="action-buttons__content_group">
        <template #top>
          <SharedKitButton
            :text="$t('battles.actionButtons.readyNow')"
            :is-loading="props.buttonsState.startingWithPlayers && !props.buttonsState.cancelling"
            :is-disabled="props.buttonsState.startingWithBots && !props.buttonsState.cancelling"
            v-bind="presetBtn('primary', { typePreset: defineRadiusForButtons('top') })"
            @click.once="$emit('offer-start')"
          >
            <template v-if="isDesktop" #append>
              <BaseTooltip position="top">
                {{ textTooltipStartedNowButton }}
              </BaseTooltip>
            </template>
          </SharedKitButton>
        </template>
        <template #bottom>
          <SharedKitButton
            :text="$t('battles.actionButtons.readyWithBots')"
            :is-loading="props.buttonsState.startingWithBots && !props.buttonsState.cancelling"
            :is-disabled="props.buttonsState.startingWithPlayers && !props.buttonsState.cancelling"
            v-bind="presetBtn('bot', { typePreset: defineRadiusForButtons('bottom') })"
            @click.once="$emit('offer-start-bots')"
          >
            <template v-if="isDesktop" #prepend>
              <SvgoPlayerBotIcon :height="24" :width="24" />
            </template>
            <template v-if="isDesktop" #append>
              <BaseTooltip position="bottom">
                {{ $t('battles.actionButtons.offerToPlayWithBots') }}
              </BaseTooltip>
            </template>
          </SharedKitButton>
        </template>
      </BattlesActionButtonsGroup>
    </div>
    <div v-if="isGuestWithOffer" class="action-buttons__content">
      <BattlesActionButtonsGroup :hide-buttons="{ bottom: !isMe }" :class="buttonActionClasses">
        <template #top>
          <SharedKitButton
            :text="offerData.text"
            class="group__offer"
            v-bind="
              presetOfferButton('secondary', {
                typePreset: defineRadiusForButtons(radiusForButton, !isMe),
              })
            "
          >
            <template v-if="showBotIcon" #prepend>
              <SvgoPlayerBotBlueIcon :height="24" :width="24" />
            </template>
          </SharedKitButton>
        </template>
        <template #bottom>
          <SharedKitButton
            :text="$t('battles.actionButtons.cancel')"
            :is-loading="props.buttonsState.cancelling"
            v-bind="presetBtn('secondary', { typePreset: defineRadiusForButtons(radiusForButton) })"
            @click.once="$emit('cancel-offer')"
          />
        </template>
      </BattlesActionButtonsGroup>
    </div>
    <div v-if="isWaiting" class="action-buttons__content">
      <BattlesActionButtonsGroup class="action-buttons__content_group">
        <template #top>
          <SharedKitButton
            :text="$t('battles.actionButtons.readyNow')"
            v-bind="
              presetBtn('tertiary', {
                typePreset: defineRadiusForButtons('top'),
                colorPreset: { textColor: { colorStops: BattlesColors.MAIN_LIGHT, toDirection: '45deg' } },
              })
            "
          >
          </SharedKitButton>
        </template>
        <template #bottom>
          <SharedKitButton
            :text="$t('battles.actionButtons.cancel')"
            :is-loading="props.buttonsState.startingWithBots"
            :is-disabled="props.buttonsState.startingWithPlayers"
            v-bind="presetBtn('secondary', { typePreset: defineRadiusForButtons('bottom') })"
            @click.once="$emit('cancel-waiting')"
          />
        </template>
      </BattlesActionButtonsGroup>
    </div>
  </section>
</template>

<script lang="ts" setup>
import type { IActionButtonsEmits, IActionButtonsProps } from './ActionButtons.types';

import { BattlesColors } from '~/features/battles/constants/colors';
import { Colors } from '~/constants/colors.constants';
import { createPresetor } from '~/features/battles/presets/buttons.presets';
import { defineRadiusForButtons } from '~/features/battles/constants/actionsButtonsGroup';
import type { TBackgroundColor } from '~/types/Shared.types';

const props = defineProps<IActionButtonsProps>();
defineEmits<IActionButtonsEmits>();

const { hasPlayers } = toRefs(props);

const { t } = useI18n();

const textTooltipStartedNowButton = computed(() => {
  if (hasPlayers.value) {
    return props.isHost ? t('battles.actionButtons.startBattleNow') : t('battles.actionButtons.offerToStartBattle');
  } else return t('battles.actionButtons.battleStartInfo');
});

const { xl } = useMedia();
const changeXL = GlobalUtils.Media.changeByMedia('xl');

const isDesktop = computed(() => xl.value);

const isStartWithBots = computed(() => props.offer === 'start-bots');
const isGuestWithoutOffer = computed(() => !props.isHost && !props.offer);
const isGuestWithOffer = computed(() => !props.isHost && props.offer);

const showBotIcon = computed(() => isDesktop.value && props.offer === 'start-bots');

const readyPlayerText = computed(() =>
  changeXL(t('battles.actionButtons.readyNow'), t('battles.actionButtons.readyToStart')),
);
const readyBotText = computed(() =>
  changeXL(t('battles.actionButtons.readyToStartWithBots'), t('battles.actionButtons.readyWithBots')),
);

// истекло ли 10-секундное ожидание в одиночестве для хоста
const isWaitingTimeForHost = ref(false);

// для последующей очистки
let hostTimeout: NodeJS.Timeout;

const offerData = computed(() => {
  if (!props.offer) return { color: '', text: '' };

  return {
    color: {
      colorStops:
        props.offer === 'start'
          ? BattlesColors.MAIN_LIGHT
          : [BattlesColors.BOT_BLUE_LIGHT[0], BattlesColors.BOT_BLUE_LIGHT[0]],
      toDirection: GlobalUtils.Converting.toDegs(88),
    } as TBackgroundColor,
    text: props.offer === 'start' ? readyPlayerText.value : readyBotText.value,
  };
});

const presetBtn = computed(() =>
  createPresetor(changeXL, {
    sizePreset: {
      height: changeXL(40, 32),
    },
    typePreset: {
      large: 0,
      medium: 0,
    },
  }),
);

const presetOfferButton = computed(() =>
  createPresetor(changeXL, {
    colorPreset: {
      defaultBackground: Colors.NEUTRAL.BLACK_30,
      textColor: offerData.value.color,
    },
    typePreset: {
      large: 0,
      medium: 0,
    },
  }),
);

const radiusForButton = computed(() => (isStartWithBots.value ? 'top' : 'bottom'));

const buttonActionClasses = computed(() => ({
  'action-buttons__content_group': true,
  'action-buttons__content_reverse': isStartWithBots.value,
}));

watch(
  hasPlayers,
  (value) => {
    if (!value && props.isHost) {
      hostTimeout = setTimeout(() => {
        isWaitingTimeForHost.value = true;
      }, 10000);
    } else {
      isWaitingTimeForHost.value = false;
      clearTimeout(hostTimeout);
    }
  },
  { immediate: true },
);

onUnmounted(() => {
  clearTimeout(hostTimeout);
});
</script>

<style lang="scss" scoped>
.action-buttons {
  --ready-color: v-bind('offerData.color');
}
</style>

<style lang="scss" scoped src="./ActionButtons.scss" />
