<template>
  <div class="feed-item">
    <div :class="feedItemClasses">
      {{ feedItemTypeText }}
    </div>
    <TransitionGroup name="fade">
      <template v-for="(array, key) in displayedItems" :key="key">
        <Drop
          v-for="item in array"
          :key="item.inventoryId"
          :item="item"
          :has-multi-hover="item.hasMultiHover"
          :shining="isUltra"
          :shining-color="GlobalUtils.Colors.getColorsRarity(item.quality || '')"
          @mouseenter.passive="feedStore.handleMultiHover(item)"
          @mouseleave.passive="feedStore.handleMultiHover(item, false)"
        />
      </template>
    </TransitionGroup>
  </div>
</template>

<script lang="ts" setup>
import type { IFeedItemProps } from '~/project-layers/dota/components/FeedItem/FeedItem.types';
import { useFeedStore } from '~/project-layers/dota/features/live-drop/store/feedStore';

const { t } = useNuxtApp().$i18n;
const changeMD = GlobalUtils.Media.changeByMedia('md');

const props = withDefaults(defineProps<IFeedItemProps>(), { isUltra: false });

const feedStore = useFeedStore();

// Ограничиваем количество элементов для ультра-редких предметов для разных разрешений
const displayedItems = computed(() => {
  if (!props.isUltra) return props.dropItems;

  const itemsLimit = changeMD(true, false) ? 3 : 2;
  return Object.fromEntries(Object.entries(props.dropItems).slice(0, itemsLimit));
});

const feedItemTypeText = computed(() => {
  if (props.isUltra) {
    return t('ultra');
  }
  return feedStore.isPaused ? t('pause') : t('live');
});

const feedItemClasses = computed(() => ({
  'feed-item__type': true,
  'feed-item__type--ultra': props.isUltra,
  'feed-item__type--pause': !props.isUltra && feedStore.isPaused,
}));
</script>

<style scoped lang="scss" src="./FeedItem.scss" />
